var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var qG,rG,tG,uG,xha,yha,zha,vG,sG;$CLJS.oG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.pG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
qG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.Bk)?$CLJS.$F:$CLJS.Dz(b,$CLJS.Qk)?$CLJS.VF:$CLJS.Dz(b,$CLJS.$i)?$CLJS.XF:null;return $CLJS.n(b)?$CLJS.lE(b,a):!0};
rG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.oG(function(d){return $CLJS.Dz($CLJS.ID(d),$CLJS.$B)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.bk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ID($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(qG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
tG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.aj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(rG(b))].join("")}],null),$CLJS.Oe(rG)],null)],null)};
uG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.oE,$CLJS.cf.g(function(b){var c=$CLJS.ID(b),d=$CLJS.Dz(c,$CLJS.VD);b=d?$CLJS.lE($CLJS.kF,b):d;return $CLJS.n(b)?$CLJS.ij:c},a))};yha=function(a){a=$CLJS.pG(function(b){return!$CLJS.Dz(b,$CLJS.$B)},$CLJS.cf.g($CLJS.ID,a));return $CLJS.Dz(a,$CLJS.VD)?$CLJS.nj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.ID(c),$CLJS.$B)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.ID(c),$CLJS.Bk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.ID(c),$CLJS.$i)},b))?$CLJS.$B:xha(b)};vG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.wG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);sG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.ID(a);return $CLJS.Le(function(d){return qG(d,c)},b)}],null)],null));
$CLJS.Y(vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null)],null)],
null);$CLJS.FF.g($CLJS.Mq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,tG($CLJS.Mq),uG($CLJS.Mq)],null));$CLJS.FF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,tG($CLJS.Zr),Aha,uG($CLJS.Zr)],null));$CLJS.vE($CLJS.Nq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,vG],null)]));$CLJS.vE($CLJS.AF,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,vG],null)]));
for(var xG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mq,$CLJS.Zr,$CLJS.Nq],null)),yG=null,zG=0,AG=0;;)if(AG<zG){var Bha=yG.X(null,AG);$CLJS.mE(Bha,$CLJS.wG);AG+=1}else{var BG=$CLJS.y(xG);if(BG){var CG=BG;if($CLJS.vd(CG)){var DG=$CLJS.ic(CG),Cha=$CLJS.jc(CG),Dha=DG,Eha=$CLJS.D(DG);xG=Cha;yG=Dha;zG=Eha}else{var Fha=$CLJS.z(CG);$CLJS.mE(Fha,$CLJS.wG);xG=$CLJS.B(CG);yG=null;zG=0}AG=0}else break}
$CLJS.HD.m(null,$CLJS.wG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.tE($CLJS.GE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.mE($CLJS.GE,$CLJS.ZD);
for(var EG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.xE,$CLJS.UE],null)),FG=null,GG=0,HG=0;;)if(HG<GG){var Gha=FG.X(null,HG);$CLJS.tE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));HG+=1}else{var IG=$CLJS.y(EG);if(IG){var JG=IG;if($CLJS.vd(JG)){var KG=$CLJS.ic(JG),Hha=$CLJS.jc(JG),Iha=KG,Jha=$CLJS.D(KG);EG=Hha;FG=Iha;GG=Jha}else{var Kha=$CLJS.z(JG);$CLJS.tE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));EG=$CLJS.B(JG);FG=null;GG=0}HG=0}else break}
for(var LG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,$CLJS.eF,$CLJS.lF],null)),MG=null,NG=0,OG=0;;)if(OG<NG){var Lha=MG.X(null,OG);$CLJS.tE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.Ui,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));OG+=1}else{var PG=$CLJS.y(LG);if(PG){var QG=PG;if($CLJS.vd(QG)){var RG=$CLJS.ic(QG),Mha=$CLJS.jc(QG),Nha=RG,Oha=$CLJS.D(RG);LG=Mha;MG=Nha;NG=Oha}else{var Pha=$CLJS.z(QG);$CLJS.tE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.Ui,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));LG=$CLJS.B(QG);MG=null;NG=0}OG=0}else break}$CLJS.tE($CLJS.DE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));
$CLJS.HD.m(null,$CLJS.DE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.ID(b),$CLJS.Ui)&&$CLJS.Dz($CLJS.ID(a),$CLJS.Ui)?$CLJS.Ui:$CLJS.KC});