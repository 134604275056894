var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Bta,Cta,M7,Dta,Eta,Fta,Gta,Hta,Ita,Jta,N7,O7,P7;
Bta=function(a){return function(b){var c=$CLJS.Ve($CLJS.Sg);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Cd($CLJS.q(c),t))return l;c.nd(null,$CLJS.Yd.g(c.Lb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Cta=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.cD(function(d){return $CLJS.Y_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.H_,c,$CLJS.B_,!1],null))}),$CLJS.DM.h($CLJS.EV(a,b)))};M7=function(a,b,c){return $CLJS.Wf.j($CLJS.xf,$CLJS.cD(function(d){return $CLJS.W_.v(a,b,d,c)}),$CLJS.DM.h($CLJS.EV(a,b)))};$CLJS.B3=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.PV.l(c,d,$CLJS.R,$CLJS.H([$CLJS.p7,$CLJS.W_.j(c,d,$CLJS.EV(c,d))]))},a,$CLJS.qt(0,$CLJS.DV(a,b)))};
Dta=function(a,b){a=$CLJS.EV(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Vs),d=$CLJS.J.g(a,$CLJS.NU);b=$CLJS.p7.h(a);if($CLJS.n(b))return b;var e=$CLJS.nU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.aV);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.VY;case "mbql.stage/mbql":return $CLJS.OY;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Be(function(){return function m(l){return new $CLJS.ie(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A);C=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.uY,$CLJS.U.h(C),$CLJS.KY,$CLJS.U.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.KJ,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);return $CLJS.ae($CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.uY,$CLJS.U.h(x),$CLJS.KY,$CLJS.U.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.KJ,f],null)])),m($CLJS.Hc(t)))}return null}},null,null)}($CLJS.BV.h(e))}())}return null};
Eta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.zz($CLJS.uY,$CLJS.U)(x);x=$CLJS.l1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.KJ,$CLJS.ZJ,$CLJS.H([$CLJS.uY,S,$CLJS.KY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.KJ,$CLJS.ZJ,$CLJS.H([$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.U)(u),$CLJS.KY,function(){var v=$CLJS.l1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.T5.g(a,b))}())};
Fta=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.KJ,$CLJS.hK,$CLJS.H([$CLJS.uY,S,$CLJS.KY,x]));A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=
$CLJS.z(k);return $CLJS.ae($CLJS.R.l(u,$CLJS.KJ,$CLJS.hK,$CLJS.H([$CLJS.uY,$CLJS.U.h(u),$CLJS.KY,function(){var v=$CLJS.U.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.v4.g(a,b))}())};
Gta=function(a,b,c){var d=$CLJS.EV(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.OD);return $CLJS.Be(function(){return function l(k){return new $CLJS.ie(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.hd(t,K),X=$CLJS.I(S,0,null),T=function(){var oa=X;oa=oa instanceof $CLJS.M?oa.S:null;switch(oa){case "field":return $CLJS.TY;case "expression":return $CLJS.EY;default:throw Error(["No matching clause: ",
$CLJS.p.h(oa)].join(""));}}(),da=$CLJS.j_.j(a,b,S);$CLJS.pe(v,$CLJS.R.l(da,$CLJS.KJ,T,$CLJS.H([$CLJS.uY,$CLJS.O_.j(a,b,da),$CLJS.KY,function(){var oa=$CLJS.l1(a,da);return c.h?c.h(oa):c.call(null,oa)}()])));K+=1}else return!0}()?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.S:null;switch(K){case "field":return $CLJS.TY;case "expression":return $CLJS.EY;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.j_.j(a,b,x);return $CLJS.ae($CLJS.R.l(G,$CLJS.KJ,C,$CLJS.H([$CLJS.uY,$CLJS.O_.j(a,b,G),$CLJS.KY,function(){var K=$CLJS.l1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Hc(m)))}return null}},null,null)}(e)}())}return null};Hta=function(a,b,c){return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.cD(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[Eta,Fta],null)))};
Ita=function(a,b,c){var d=$CLJS.J1(a,b);return $CLJS.n(d)?$CLJS.Be(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.hd(m,A);var G=$CLJS.zz($CLJS.KY,$CLJS.uY)(C);G=$CLJS.n(G)?G:$CLJS.O_.j(a,b,C);C=$CLJS.Vj.l($CLJS.uk.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.KJ,$CLJS.vY,$CLJS.uY,G,$CLJS.KY,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.q1.h(C))?
$CLJS.Gl(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.q1],null)):null])),$CLJS.IV,$CLJS.H([$CLJS.mD]));u.add(C);A+=1}else return!0}()?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.zz($CLJS.KY,$CLJS.uY)(v);return $CLJS.n(A)?A:$CLJS.O_.j(a,b,v)}();return $CLJS.ae($CLJS.Vj.l($CLJS.uk.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.KJ,$CLJS.vY,$CLJS.uY,x,$CLJS.KY,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.q1.h(v))?$CLJS.Gl(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.q1],null)):null])),$CLJS.IV,$CLJS.H([$CLJS.mD])),k($CLJS.Hc(l)))}return null}},null,null)}($CLJS.W_.j(a,d,$CLJS.EV(a,d)))}()):null};Jta=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.f_(a,c),$CLJS.n(c)?$CLJS.Be($CLJS.Y_.v(a,b,c,d)):null):null};
N7=function(a,b,c){return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v),A=t;var C=$CLJS.tA.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.U.h(x);x=$CLJS.U.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.G1(G.call($CLJS.R,K,$CLJS.KJ,$CLJS.EY,$CLJS.H([$CLJS.uY,S,$CLJS.KY,x])),$CLJS.mi,$CLJS.n(C)?C:$CLJS.Yi);A.add(C);v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),
f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k);return $CLJS.ae(function(){var v=$CLJS.tA.h(u),x=$CLJS.R.l,A=u,C=$CLJS.U.h(u);var G=$CLJS.U.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.G1(x.call($CLJS.R,A,$CLJS.KJ,$CLJS.EY,$CLJS.H([$CLJS.uY,C,$CLJS.KY,G])),$CLJS.mi,$CLJS.n(v)?v:$CLJS.Yi)}(),f($CLJS.Hc(k)))}return null}},null,null)}($CLJS.Epa.g(a,b))}())};
O7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.H_);return $CLJS.ek.g(function(f){return $CLJS.Vj.l(f,$CLJS.eZ,$CLJS.H([$CLJS.IV,$CLJS.NV,$CLJS.WJ]))},function(){var f=Ita(a,b,e);if($CLJS.n(f))return f;f=$CLJS.EV(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.eO),l=$CLJS.J.g(f,$CLJS.NU),m=$CLJS.n(k)?function(){var t=$CLJS.d_(a,k);return $CLJS.Y_.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Jta(a,b,l,$CLJS.R.j(d,$CLJS.B_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.ie(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var S=0;;)if(S<C){var X=$CLJS.hd(A,S),T=G,da=$CLJS.R,oa=da.l,Ma=X,sb=$CLJS.U.h(X);X=$CLJS.U.h(X);X=e.h?e.h(X):e.call(null,X);da=oa.call(da,Ma,$CLJS.KJ,$CLJS.VY,$CLJS.H([$CLJS.uY,sb,$CLJS.KY,X]));T.add(da);S+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x);return $CLJS.ae($CLJS.R.l(K,$CLJS.KJ,$CLJS.VY,$CLJS.H([$CLJS.uY,$CLJS.U.h(K),
$CLJS.KY,function(){var S=$CLJS.U.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.BV.h($CLJS.nU.h(f)))}())};P7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.lra={};$CLJS.mE($CLJS.UU,P7);$CLJS.mE($CLJS.aV,P7);$CLJS.R0.m(null,$CLJS.UU,function(a){return $CLJS.Q0(a,new $CLJS.h(null,2,[$CLJS.WE,$CLJS.Qe($CLJS.ek,$CLJS.R0),$CLJS.hV,$CLJS.Qe($CLJS.ek,$CLJS.R0)],null))});$CLJS.T_.m(null,P7,function(){throw $CLJS.Uh("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.X_.m(null,P7,function(a,b,c,d){var e=$CLJS.O(d);c=$CLJS.J.g(e,$CLJS.H_);d=$CLJS.J.g(e,$CLJS.B_);a=$CLJS.B3(a,b);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.H_),k=$CLJS.J.g(e,$CLJS.F_),l=$CLJS.J.g(e,$CLJS.L_);e=$CLJS.bf.l(O7(a,b,e),$CLJS.n(l)?N7(a,b,f):null,$CLJS.H([$CLJS.n(k)?Cta(a,b,f):null]));return $CLJS.yf($CLJS.bf.g(e,$CLJS.n(d)?$CLJS.m1(a,b,e,c):null))});
$CLJS.V_.m(null,P7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.H_);var e=Dta(a,b);if($CLJS.n(e))return e;a=$CLJS.B3(a,b);e=Hta(a,b,d);var f=Gta(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.ch(f),$CLJS.Wf.j($CLJS.xf,Bta(function(k){return $CLJS.Vj.l(k,$CLJS.ZY,$CLJS.H([$CLJS.KJ,$CLJS.D0,$CLJS.KY]))}),$CLJS.bf.g(f,M7(a,b,c)))):$CLJS.bf.l(O7(a,b,new $CLJS.h(null,2,[$CLJS.B_,!1,$CLJS.H_,d],null)),N7(a,b,d),$CLJS.H([M7(a,b,c)]))});$CLJS.M_.m(null,$CLJS.aV,function(){return $CLJS.FD("Native query")});
var Q7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,$CLJS.NU,$CLJS.DM],null),R7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WE,$CLJS.VE,$CLJS.hV,$CLJS.NQ,$CLJS.jR],null);
$CLJS.M_.m(null,$CLJS.UU,function(a,b,c,d){var e=$CLJS.B3(a,b);a=$CLJS.Be(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.ck.g($CLJS.qB,$CLJS.cf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.Xoa.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[Q7,R7],null)),k=$CLJS.hs(" + ",$CLJS.dk.g($CLJS.Hz,$CLJS.cf.g(f,Q7)));f=$CLJS.cf.g(f,R7);return $CLJS.hs(", ",$CLJS.dk.g($CLJS.Hz,$CLJS.ae(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.J1(e,b);return $CLJS.n(a)?$CLJS.l_.v(e,a,$CLJS.EV(e,a),d):null});